import React from 'react'
import { Link } from "react-router-dom"

import { IMAGE_BASE_URL } from '../commons'

import { dateFr } from '../utils'


const Travelbook = (props) => {

    const { travelbook } = props

    const displayImage = (travelbook) => {
        if (travelbook.image) return( <img className="img-fluid" src={`${IMAGE_BASE_URL}/${travelbook.slug}/image/${travelbook.image}`} alt=""/>)
        else return('')
    }

    const displayDates = (travelbook) => {
        const nullDate = "0000-00-00 00:00:00"
        let msg = ''
        if ((travelbook.startdate !== nullDate) && (travelbook.enddate !== nullDate)) {
            const sdate = new Date(travelbook.startdate)
            const edate = new Date(travelbook.enddate)
            msg = 'du ' + dateFr(sdate,'sansjour') + ' au ' + dateFr(edate,'sansjour')
        }
        else if (travelbook.startdate !== nullDate ) {
            const sdate = new Date(travelbook.startdate)
            msg = dateFr(sdate)
        }
        return (<p className="tspdate">{ msg }</p>) 
    }

    return (
        <div className="travelbook-item-container">
            <Link to={`/${travelbook.slug}`}>
                <h2>{travelbook.name}</h2>
                <p>{travelbook.description}</p>
                { displayDates(travelbook) }
                <div className="container-fluid d-flex justify-content-center">
                    { displayImage(travelbook) }
                </div>
            </Link>
        </div>
    )
}

export default Travelbook