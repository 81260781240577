// Font Awesome 5.13
import './style/fontawesome/css/travelsteps-fontawesome.css'
import './style/fontawesome/css/solid.css'
import './style/fontawesome/css/regular.css'

// Package Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
// Package Leaflet
import 'leaflet/dist/leaflet.css'
// Leaflet-geosearch
import 'leaflet-geosearch/dist/geosearch.css'
// BeautifyMarker
import 'beautifymarker/leaflet-beautify-marker-icon.css'

import React from 'react'
import ReactDOM from 'react-dom'


// style global
import './style/index.css'

// Redux Store
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'

import thunk from 'redux-thunk'

import App from './containers/App'
import * as serviceWorker from './serviceWorker'

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())}>
    <App />
  </Provider> 
  , document.getElementById('travelsteps'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
